import React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../components/layout/Layout';

import SplitSection from '../components/SplitSection';
import NewsletterSub from '../components/NewsletterSub';
import Button from '../components/Button';
import LeadForm from "../components/LeadForm";

const siteVerOpts = [

  {
    label: 'Ecuador',
    value: '',


  },

  {
    label: 'Internacional',
    value: '/export-es/',


  },
];

const langOpts = [


  {
    label: 'ES',
    value: '',

  },


  {
    label: 'EN',
    value: `/export-en/`,

  },


];


const seo={
  title: "Diversey",
  desc:  "Proveedor de productos de limpieza especializados en los sectores de hospitalidad, atención médica, alimentos y bebidas, servicios de alimentos, comercio, entre otros. ",
  image: "grupo-unilimpio.jpg",
  pathname: "/diversey/",
  
}

const Diversey = ({ location }) => (


  <Layout location={location} crumbLabel="Diversey"
  siteVerOpts={siteVerOpts} langOpts={langOpts}
  alternates alternateEn={seo.alternateEn} alternateEs={seo.alternateEs} alternateEsec={seo.alternateEsec} alternateDefault={seo.alternateDefault}
  title={seo.title} desc={seo.desc} banner={seo.image} pathname={seo.pathname}>

<div className="bg-fixed" >
       <StaticImage src="../images/banner-diversey.jpg"
                className="object-contain"
                alt="Unilimpio es una empresa con Resposabilidad Social"
                placeholder="blurred"
                layout="fullWidth"/>
  </div>

   



    <section id="presentacion" className="bg-gray-300 flex justify-center">
  <div className="max-w-7xl mx-10 py-16 px-4 sm:px-6 lg:py-24 lg:px-8">
    <div className="max-w-3xl mx-auto text-center">
    <h1 className="text-5xl font-semibold text-blue-900">Somos Diversey en Ecuador</h1>
           <p className="mt-4 text-2xl text-blue-900 mb-6 leading-relaxed ">El propósito de Diversey es ir más allá de la limpieza para cuidar lo valioso mediante soluciones líderes en higiene, prevención de infecciones y limpieza. Ofrecemos productos, servicios y tecnologías innovadores que salvan vidas y protegen nuestro medio ambiente. Durante más de un siglo, la marca Diversey se ha convertido en sinónimo de calidad de producto, servicio e innovación. </p>
    </div>
    </div>
</section>


<section id="silg" class="bg-blue-900 pt-2">
  <div class="max-w-7xl mx-20 py-16 px-4 sm:px-6 lg:py-24 lg:px-8 bg-blue-900">
    <div class="max-w-2xl mx-auto text-center">
      <h2 class="text-3xl font-semibold  text-white sm:text-4xl">SOLUCIONES</h2>
      <p class="mt-2 text-2xl leading-8 text-white">Nuestras soluciones de limpieza integradas de manera única lo ayudarán a ahorrar tiempo, recursos y dinero al tiempo que brindan eficiencias operativas reales.  </p>
    </div>
    </div>
</section>

<section class="  pb-20  grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 mx-0 px-2 bg-primary-default">
  <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200 ">
    <div class="flex-1 flex flex-col p-8 ">
    <StaticImage src="../images/soluciones-industria.jpg"
                    className="flex justify-center mx-6"
                    alt="Visión de Unilimpio"
                    placeholder="blurred"
                    layout="constrained"/>
      <h3 class="mt-6 text-secondary text-2xl font-semibold ">Soluciones para la Industria</h3> <br/>
      <dl class="mt-1 flex-grow flex flex-col justify-between">
        <dt class="sr-only">Title</dt>
        <dd class="text-blue-900 text-lg text-justify">En el ámbito empresarial, la limpieza no es solo una necesidad, es una inversión en la productividad y el bienestar de su equipo. Diversey ofrece soluciones de limpieza especializadas, diseñadas para mantener entornos industriales limpios y seguros. Nuestros productos abarcan desde desinfectantes de alto rendimiento hasta limpiadores de equipos, asegurando que cada rincón de su instalación refleje los estándares más altos de limpieza.</dd>
        <dt class="sr-only">Role</dt>
        <dd class="mt-3">

        </dd>
      </dl>
    </div>
  </li>

  <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
    <div class="flex-1 flex flex-col p-8">
    <StaticImage src="../images/soluciones-salud.jpg"
                    className="flex justify-center mx-6"
                    alt="Visión de Unilimpio"
                    placeholder="blurred"
                    layout="constrained"/>
      <h3 class="mt-6 text-secondary text-2xl font-semibold">Soluciones para la Salud</h3><br/>
      <dl class="mt-1 flex-grow flex flex-col justify-between">
        <dt class="sr-only">Title</dt>
        <dd class="text-blue-900 text-lg text-justify">La salud y la limpieza van de la mano, especialmente en entornos críticos como hospitales y consultorios médicos. Con la comprensión de las demandas únicas de estos espacios, Diversey ofrece una gama completa de productos especializados. Desde desinfectantes que eliminan patógenos hasta limpiadores de superficies seguros para equipos médicos, nuestras soluciones están diseñadas para proteger la salud de sus pacientes y personal, garantizando un ambiente clínico impecable. </dd>
        <dt class="sr-only">Role</dt>
        <dd class="mt-3">

        </dd>
      </dl>
    </div>

  </li>

  <li class="col-span-1 flex flex-col text-center bg-white rounded-lg shadow divide-y divide-gray-200">
    <div class="flex-1 flex flex-col p-8">
    <StaticImage src="../images/soluciones-superficies.jpg"
                    className="flex justify-center mx-6"
                    alt="Visión de Unilimpio"
                    placeholder="blurred"
                    layout="constrained"/>
      <h3 class="mt-6 text-secondary text-2xl font-semibold">Soluciones para Mnatenimiento de Pisos</h3><br/>
      <dl class="mt-1 flex-grow flex flex-col justify-between">
        <dt class="sr-only">Title</dt>
        <dd class="text-blue-900 text-lg text-justify">La limpieza de superficies es esencial en cualquier entorno, ya sea en el hogar o en instalaciones comerciales. Diversey presenta una línea completa de detergentes y limpiadores que abordan los desafíos únicos de diferentes superficies. Desde suelos hasta equipos delicados, nuestros productos garantizan una limpieza profunda y efectiva. Además, nos comprometemos con la sostenibilidad, ofreciendo opciones respetuosas con el medio ambiente sin comprometer la calidad de la limpieza.</dd>
        <dt class="sr-only">Role</dt>
        <dd class="mt-3">

        </dd>
      </dl>
    </div>

  </li>
</section>






<LeadForm/>

<NewsletterSub/>

</Layout>
);

export default Diversey;
